import { TFunction } from '@wix/yoshi-flow-editor';
import {
  getFormattedStartDate,
  getOfferedDays,
  getPrice,
} from './serviceDetails';
import { ServiceInfoLayoutOption } from '../../components/BookOnline/Widget/Body/ServiceCard/ServiceInfo/consts';
import {
  EnrichedService,
  ServiceInfoAlignmentOptions,
} from '../../types/types';
import { BusinessInfo as LegacyBusinessInfo } from '../../../legacy/types';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { getDurationText } from '@wix/bookings-calendar-catalog-viewer-mapper';
import { ServiceAvailabilityMap } from '../../api/BookingsApi';

export type ServiceInfoDisplayOptions = {
  tagLine?: string;
  offeredDays?: string;
  startDate?: string;
  duration?: string;
  price?: {
    displayedPrice?: string;
    srOnlyPrice?: string;
  };
  isPassedEndDate?: boolean;
  numberOfSpotsLeft?: number;
  isTooLateToBook?: boolean;
};

export type DisplayOptions = {
  isTagLineVisible: boolean;
  isServiceOfferedDaysVisible: boolean;
  isServiceStartDateVisible: boolean;
  isServiceDurationVisible: boolean;
  isServicePriceVisible: boolean;
};

export function isTooLateToBook(service: EnrichedService) {
  const canBeBookedAfterStart =
    service.bookingPolicy?.bookAfterStartPolicy?.enabled;
  let tooLate = false;
  const { firstSessionStart, lastSessionEnd } = service.schedule || {};

  if (!canBeBookedAfterStart && firstSessionStart) {
    const ms =
      service.bookingPolicy?.limitLateBookingPolicy?.latestBookingInMinutes! *
      60 *
      1000;
    const bookBefore = firstSessionStart.valueOf() - ms;
    tooLate = Date.now() > bookBefore;
  }
  const isPassedEndDate =
    service.type === ServiceType.COURSE &&
    (!lastSessionEnd || isDatePassedSSRSafe(lastSessionEnd));
  const isPassedStartDate =
    !firstSessionStart || isDatePassedSSRSafe(firstSessionStart);

  return (
    isPassedEndDate || (isPassedStartDate && !canBeBookedAfterStart) || tooLate
  );
}

export const generateServiceInfoDetails = ({
  displayOptions: {
    isServiceDurationVisible,
    isServiceOfferedDaysVisible,
    isServicePriceVisible,
    isServiceStartDateVisible,
    isTagLineVisible,
  },
  service,
  businessInfo,
  language,
  t,
  coursesAvailability,
  isDynamicDurationUOUEnable = false,
  isServiceListEventsClassDaysEnable = false,
}: {
  displayOptions: DisplayOptions;
  service: EnrichedService;
  businessInfo: LegacyBusinessInfo;
  language: string;
  t: TFunction;
  coursesAvailability?: ServiceAvailabilityMap;
  isDynamicDurationUOUEnable?: boolean;
  isServiceListEventsClassDaysEnable?: boolean;
}): ServiceInfoDisplayOptions => {
  const tagLine = isTagLineVisible ? service.tagLine! : undefined;
  const offeredDays =
    isServiceOfferedDaysVisible && service.type === ServiceType.CLASS
      ? getOfferedDays(service, t, isServiceListEventsClassDaysEnable)
      : undefined;
  const isCourseType = service.type === ServiceType.COURSE;
  const { lastSessionEnd } = service.schedule!;
  const isPassedEndDate =
    isCourseType && (!lastSessionEnd || isDatePassedSSRSafe(lastSessionEnd));
  const startDate =
    isServiceStartDateVisible && !isPassedEndDate && isCourseType
      ? getFormattedStartDate({ service, language, t, businessInfo })
      : undefined;
  const duration =
    isServiceDurationVisible &&
    (service.type === ServiceType.CLASS ||
      service.type === ServiceType.APPOINTMENT)
      ? getDurationText({
          dateRegionalSettingsLocale: businessInfo.regionalSettings,
          service,
          durationsMapperKeys: {
            hourUnit: 'duration.units.hours',
            minuteUnit: 'duration.units.minutes',
            durationVaries: 'duration.varies',
          },
          durationFormatter: t,
          isDynamicDurationUOUEnable,
        })
      : undefined;

  const price = isServicePriceVisible
    ? getPrice({ service, language, locale: businessInfo.regionalSettings, t })
    : undefined;
  const numberOfSpotsLeft = coursesAvailability?.[service.id!]?.spotsLeft;

  return {
    tagLine,
    offeredDays,
    startDate,
    duration,
    price,
    isPassedEndDate,
    numberOfSpotsLeft,
    isTooLateToBook: isCourseType ? isTooLateToBook(service) : undefined,
  };
};

function testServiceInfoDetailsCenterAlignmentForHorizontal({
  startDate,
  offeredDays,
  price,
  tagLine,
  duration,
}: ServiceInfoDisplayOptions) {
  const numberOfDetailsInMiddleColumn = [
    offeredDays,
    startDate,
    duration,
    price?.displayedPrice,
  ].filter((detail) => detail).length;
  return !tagLine && numberOfDetailsInMiddleColumn < 2;
}

function testServiceInfoDetailsCenterAlignmentForVertical(
  {
    startDate,
    offeredDays,
    price,
    tagLine,
    duration,
  }: ServiceInfoDisplayOptions,
  isServiceDividerVisible: boolean,
) {
  const numberDetails = [
    tagLine,
    offeredDays,
    startDate,
    duration,
    price?.displayedPrice,
  ].filter((detail) => detail).length;
  return !isServiceDividerVisible && numberDetails === 0;
}

export const getDetailsAlignment = ({
  allServiceInfoDetails,
  serviceInfoLayout,
  isServiceDividerVisible,
}: {
  allServiceInfoDetails: ServiceInfoDisplayOptions[];
  isServiceDividerVisible: boolean;
  serviceInfoLayout: ServiceInfoLayoutOption;
}): ServiceInfoAlignmentOptions => {
  return allServiceInfoDetails.every(
    serviceInfoLayout === ServiceInfoLayoutOption.HORIZONTAL
      ? testServiceInfoDetailsCenterAlignmentForHorizontal
      : (serviceInfoDisplayOptions: ServiceInfoDisplayOptions) =>
          testServiceInfoDetailsCenterAlignmentForVertical(
            serviceInfoDisplayOptions,
            isServiceDividerVisible,
          ),
  )
    ? ServiceInfoAlignmentOptions.CENTER
    : ServiceInfoAlignmentOptions.TOP;
};

const isDatePassedSSRSafe = (date: string | Date) =>
  new Date(date).valueOf() < new Date().valueOf();
